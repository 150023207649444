"use client"

import React from "react"
import Image from "next/image"
import { Icon } from "@iconify/react"
import { Button } from "@nextui-org/react"
import clsx from "clsx"
import LoginBackground from "public/page-background-1.svg"
import RhythmsLogo from "public/rhythms-logo-login.svg"

import { fontPoppins } from "@/lib/fonts"

import { useLogin } from "./use-login"

const Login = () => {
  const { ssoProviderMutation } = useLogin()
  const buttons = [
    {
      icon: "logos:google-icon",
      onPress: () => ssoProviderMutation.mutate("google"),
      label: "Continue with Google",
    },
    {
      icon: "logos:microsoft-icon",
      label: "Continue with Microsoft",
    },
    {
      icon: "fluent:key-24-regular",
      label: "Single sign-on (SSO)",
    },
  ]
  return (
    <div className={`relative flex h-screen w-screen ${fontPoppins.className}`}>
      {/* Login Form - Left side*/}
      <div
        className={clsx(
          "flex w-full shrink-0 flex-col items-center justify-center gap-[42px] bg-background",
          "px-[80px] lg:w-[43%] xl:pl-[107px] xl:pr-[90px] 2xl:pl-[122px] 2xl:pr-[98px] 3xl:px-[140px]"
        )}
      >
        <p className="text-xl font-semibold leading-8 text-violet-800 2xl:text-[28px]">
          Sign in
        </p>

        <div className="flex flex-col gap-3">
          {buttons.map((button) => {
            return (
              <Button
                key={button.label}
                startContent={<Icon icon={button.icon} width={24} />}
                className="h-[52px] w-[371px] gap-4 border-2 border-default bg-white"
                radius="md"
                variant="bordered"
                onPress={button.onPress}
              >
                <span
                  className={clsx(
                    "w-40 text-start text-[14px] font-semibold leading-6 text-zinc-800 2xl:text-base 2xl:leading-6"
                  )}
                >
                  {button.label}
                </span>
              </Button>
            )
          })}
        </div>
      </div>

      {/* Right side */}
      <div
        className={clsx(
          "relative hidden w-[57%] bg-white lg:flex",
          "relative flex h-full flex-1 flex-col justify-center gap-6 px-24",
          "xl:px-[100px] 2xl:px-[120px] 4xl:px-[180px] 5xl:px-[220px]"
        )}
      >
        {/* Fixed image background */}
        <div className="pointer-events-none absolute right-0 top-0 overflow-hidden">
          <Image src={LoginBackground} alt="" />
        </div>
        <div
          className={clsx("absolute top-[88px] flex gap-4", "xl:top-[110px]")}
        >
          <Image
            height={55}
            width={55}
            src={RhythmsLogo}
            alt="RhythmsAI Logo"
          />
          <p
            className={clsx(
              "flex items-center font-sans text-[28px] font-semibold leading-[44.5px] text-primary-500",
              "xl:text-[37px] 4xl:text-[48px]"
            )}
          >
            RhythmsAI
          </p>
        </div>
        <p
          className={clsx(
            "bg-clip-text pb-2 text-transparent [-webkit-background-clip:text] [background-image:linear-gradient(96.01deg,#834DF1_2.42%,#B726B7_37.42%)]",
            "text-wrap text-[40px] font-semibold leading-normal",
            "xl:text-[46px]",
            "2xl:text-[52px]",
            "4xl:text-[64px]"
          )}
        >
          AI-powered operating system for high-performing teams
        </p>
        <p
          className={clsx(
            "text-wrap text-xl font-normal leading-[36px] text-zinc-800",
            "3xl:text-2xl 3xl:leading-[36px]",
            "4xl:text-[32px] 4xl:leading-[48px]"
          )}
        >
          Understand, streamline and orchestrate your organization&apos;s
          rhythms. Enable every team to become a super team.
        </p>
      </div>
    </div>
  )
}

export { Login }
