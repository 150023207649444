import { ssoProvider } from "@/services/auth/auth"
import { useMutation } from "@tanstack/react-query"

const useLogin = () => {
  const ssoProviderMutation = useMutation({
    mutationFn: (provider: string) => {
      return ssoProvider(provider)
    },
    onSuccess: (data) => {
      if (data?.authorize_url) {
        window.location.href = data.authorize_url
      }
    },
    onError: (error) => {
      alert(error)
    },
  })

  return {
    ssoProviderMutation,
  }
}

export { useLogin }
