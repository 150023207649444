import { fetchJson } from "../utils"
import { SSOCallbackResponse, SSOProviderResponse } from "./auth.types"

const BASE_API_URL = "/api/auth/sso"

/**
 * Requests Single Sign-On (SSO) provider information.
 * @param {string} provider - The SSO provider.
 * @returns {Promise<SSOProviderResponse|undefined>} - The SSO provider response.
 */
export const ssoProvider = async (
  provider: string
): Promise<SSOProviderResponse | undefined> => {
  try {
    const result = await fetchJson<SSOProviderResponse>(
      `${BASE_API_URL}/${provider}`,
      {
        method: "POST",
        body: { provider },
      }
    )

    return result
  } catch (error) {
    throw new Error(`Failed to fetch SSO provider information`)
  }
}

/**
 * Handles the callback from Single Sign-On (SSO) providers.
 * @param {string} queryString - The query string for the callback.
 * @returns {Promise<SSOCallbackResponse>} - The SSO callback response.
 */
export const ssoCallback = async (
  queryString: string,
  provider: string
): Promise<SSOCallbackResponse> => {
  try {
    const result = await fetchJson<SSOCallbackResponse>(
      `${BASE_API_URL}/${provider}/callback?${queryString}`,
      {
        method: "GET",
      }
    )
    return result
  } catch (error) {
    throw new Error(`Failed to handle SSO callback`)
  }
}
