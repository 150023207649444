export interface RequestOptions {
  method?: string
  body?: object
  headers?: Record<string, string>
}

export const fetchJson = async <T>(
  url: string,
  options: RequestOptions = {}
): Promise<T> => {
  const response = await fetch(url, {
    ...options,
    method: options.method ?? "GET",
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    body: options.body ? JSON.stringify(options.body) : undefined,
  })

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`)
  }

  return response.json() as Promise<T>
}
